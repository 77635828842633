<template>
  <div class="div-main-list-page-table" v-loading="isJobTableLoading">
    <template v-if="isMobileScreen">
      <job-card-list
        v-if="actionList && $props.jobTableData"
      :jobList="$props.jobTableData"
      :actionList="actionList"
      :hasTableMenu="$props.hasTableMenu"
      :getJobTableMenuList="getJobTableMenuList"
      @handleJobDropdownMenuClick="handleJobDropdownMenuClick"
      />

    </template>
    <el-table
      ref="jobTable"
      v-if="!isJobTableLoading &&!isMobileScreen"
      :data="jobTableData"
      height="100%"
      :border="true"
      :header-cell-style="HEADER_CELL_STYLE"
      :cell-style="CELL_STYLE"
      @selection-change="handleJobSelectionChange"
    >
      <el-table-column fixed width="40" v-if="hasCheckbox" type="selection" />
      <el-table-column
        label="Status"
        width="120"
        v-bind:class-name="activeTab === 4 ? 'mpa-status-cell' : ''"
      >
        <template slot-scope="scope">
          <div
            class="mpa-status-flag"
            v-if="activeTab == 4 && scope.row.mpaTransactionStatus != null"
          >
            <el-tooltip
              v-if="scope.row.mpaTransactionStatus.successfulCount > 0"
              class="item"
              effect="dark"
              content="MPA transaction successful"
              placement="right-end"
            >
              <span style="color: green"> ✓ </span>
            </el-tooltip>
            <el-tooltip
              v-else-if="scope.row.mpaTransactionStatus.pendingCount > 0"
              class="item"
              effect="dark"
              content="MPA transaction in progressing"
              placement="right-end"
            >
              <span style="color: grey"> ... </span>
            </el-tooltip>
            <el-tooltip
              v-else
              class="item"
              effect="dark"
              content="MPA transaction failed"
              placement="right-end"
            >
              <span style="color: red"> 〤 </span>
            </el-tooltip>
          </div>

          <div
            class="status-tag"
            :style="getStatusTagStyle(STATUS_MAPPING[scope.row.status].name)"
          >
            {{ STATUS_MAPPING[scope.row.status].name }}
          </div>
          <div v-if="showReissued(scope.row)" class="duplicated">
            <img alt="img" src="@/assets/images/mobile/reissued.png" />
          </div>
          <div v-if="showReissuanceInProgress(scope.row)" class="invalidationInProgressButton">
            <img alt="img" src="@/assets/images/mobile/reissuance_in_progress.png" />
          </div>
          <div v-if="showInvalidationInProgress(scope.row)" class="invalidationInProgressButton">
            <img alt="img" src="@/assets/images/mobile/invalidation_in_progress_button.png" />
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="[3,4,5,6].includes(activeTab)" label="BDN Number" width="150">
        <template slot-scope="scope">
          <div v-for="bdnNumber in scope.row.bdnNumbers">{{ bdnNumber }}</div>
        </template>
      </el-table-column>
      <el-table-column v-if="[4].includes(activeTab)" label="Invalidated BDN" width="150">
        <template slot-scope="scope">
          <div v-for="bdnNumber in splitInvalidatedBdnNumber(scope.row.invalidatedBdnNumber)">{{ bdnNumber }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Job Type" prop="typeDisplay" width="120" />
      <!-- Test Code -->
      <el-table-column label="Link Order" width="100">
        <template v-slot="scope">
          <span >{{ !!scope.row?.orderId?'Yes':'No' }} </span>
        </template>
      </el-table-column>
      <el-table-column label="Buyer Company" width="160">
        <template v-slot="scope">
          <span>{{scope.row.buyer ?? (scope.row.buyerSysOrganizationName ?? "-") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Seller Company" width="160">
        <template v-slot="scope">
          <span>{{scope.row.seller ?? (scope.row.sellerSysOrganizationName ?? "-")}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Barge Owner Company" width="160" prop="bargeOwnerCompanyName" />
      <el-table-column label="BDN Company" width="160" prop="bdnOrganizationName" />
      <el-table-column label="Vessel/Terminal" width="150">
        <template v-slot="scope">
          <span v-if="scope.row.type === 2">{{
            scope.row.exWharfType === 0
              ? scope.row.locationLevel1 || "-"
              : scope.row.floaterName || scope.row.locationLevel1 || "-"
          }}</span>
          <span v-else>{{ scope.row.vesselName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Vessel Imo" width="100">
        <template v-slot="scope">
          <span>{{ scope.row.vesselImo || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Stem Date"
        class="order-date-column"
        prop="orderDateDisplay"
        width="90"
      >
        <template v-slot="scope">
          <div>
            {{
              scope.row.stemStartDate
                ? $moment(scope.row.stemStartDate).format("YYYY-MM-DD")
                : "-"
            }}
          </div>
          <div>
            {{
              scope.row.stemEndDate
                ? $moment(scope.row.stemEndDate).format("YYYY-MM-DD")
                : "-"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isViewOnly || [3, 4, 5, 6].includes(activeTab)"
        prop="datetime"
        label="Vessel ETA/Loading Nom"
        width="180"
      >
        <template v-slot="scope">
          <span>
            {{
              scope.row.datetime
                ? $moment(scope.row.datetime).format("YYYY-MM-DD HH:mm")
                : "-"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="datetime" label="Vessel ETA/Loading Nom" v-else width="180">
        <template slot-scope="scope">
          <el-date-picker
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            style="width: 100%"
            v-model="scope.row.datetime"
            :disabled="
              [3, 4, 5, 6].includes(scope.row.status) ||
              !isJobCreatorOrCustomerEditView(scope.row)
            "
            @change="rowValueChange(scope.row, 'datetime')"
          >
          </el-date-picker>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isViewOnly || [3, 4, 5, 6].includes(activeTab)"
        prop="datetime"
        label="Location/Berth"
        width="250"
      >
        <template v-slot="scope">
          <span>
            {{
              scope.row.locationLevel1 && scope.row.locationLevel2
                ? `${scope.row.locationLevel1} / ${scope.row.locationLevel2}`
                : scope.row.locationLevel1 || scope.row.locationLevel2 || "-"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="Location/Berth" v-else width="230">
        <template slot-scope="scope">
          <el-cascader
            clearable
            :key="cascaderKey"
            popper-class="el-cascader-long"
            v-model="scope.row.locationLevel"
            :options="locationList"
            :props="locationOptionProps"
            :disabled="
              [3, 4, 5, 6].includes(scope.row.status) ||
              !isJobCreatorOrCustomerEditView(scope.row)
            "
            style="width: 100%"
            @change="rowValueChange(scope.row)"
          ></el-cascader>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isViewOnly || [3, 4, 5, 6].includes(activeTab)"
        label="Barge"
        width="150"
      >
        <template v-slot="scope">
          <span>{{ scope.row.bargeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Barge" v-else width="150">
        <template slot-scope="scope">
          <el-select
            style="width: 100%"
            :clearable="scope.row.type !== 3"
            v-if="scope.row.mitigatingCompanyOrgId === currentCompany.id"
            show-overflow-tooltip
            v-model="scope.row.bargeName"
            :disabled="
              [2, 3, 4, 5, 6].includes(scope.row.status) ||
              !isJobCreatorOrCustomerEditView(scope.row)
            "
            placeholder=""
            @change="(val) => handleChangeBarge(val, scope.row)"
            @visible-change="visibleChange($event, scope.row)"
            :loading="availableBargesLoading"
          >
            <!-- v-for="item in availableBargesOptions"  v-for="item in getAvailableBargesOptions(scope.row)"-->
            <el-option
              v-for="item in availableBargesOptions"
              :disabled="
                scope.row.type === 3 &&
                ((item || {}).id == scope.row.vesselShipId ||
                  (item.imo && item.imo == scope.row.vesselImo))
              "
              :key="item.id"
              :label="item.shipName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input disabled v-model="scope.row.bargeName" v-else></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Product/Grade/Specs" width="250">
        <template v-slot="scope">
          <span>{{ scope.row.productDisplayName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Code" width="100">
        <template v-slot="scope">
          <span>{{ scope.row.fuelTypeCode || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Quantity" width="80">
        <template v-slot="scope">
          <span>{{ scope.row.quantity || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Remark" min-width="250">
        <template v-slot="scope">
          <el-input
            type="textarea"
            placeholder="Please input your remarks"
            style="width: 100%"
            v-model="scope.row.remark"
            :disabled="
              [3, 4, 5].includes(scope.row.status) ||
              isViewOnly ||
              !isJobCreatorOrCustomerEditView(scope.row)
            "
            @change="rowValueChange(scope.row, 'remark')"
          >
          </el-input>
        </template>
      </el-table-column>
      <el-table-column width="50px" align="center" fixed="right" v-if="hasTableMenu">
        <template v-slot="scope">
          <dropdown-action-menu
            :actionList="getJobTableMenuList(scope.row)"
            :propsData="scope.row"
            @handleDropdownMenuClick="handleJobDropdownMenuClick"
          />
        </template>
      </el-table-column>
    </el-table>

    <div
      class="div-main-list-page-table-pagination"
      v-if="hasPagination && !isJobTableLoading"
    >
      <el-pagination
        background
        @size-change="handleJobPageSizeChange"
        @current-change="handleJobPageChange"
        :page-size.sync="jobPagination.pageSize"
        :page-sizes="[5, 10, 20, 50, 100]"
        :current-page="jobPagination.pageNumber"
        :layout="paginationSetting"
        :total="jobPagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getStatusTagStyle, formatUtcTimeString } from "@/utils/index.js";
import { HEADER_CELL_STYLE, CELL_STYLE } from "@/constants.js";
import DropdownActionMenu from "@/components/DropdownActionMenu";
import JobCardList from "./JobCardList.vue";

export default {
  name: "TableJob",
  props: {
    isJobTableLoading: Boolean,
    jobTableData: Array,
    hasCheckbox: Boolean,
    jobPagination: Object,
    hasPagination: Boolean,
    jobsSelected: Array,
    isViewOnly: Boolean,
    activeTab: Number,
    availableBargesLoading: Boolean,
    availableBargesOptions: Array,
    hasTableMenu: Boolean,
  },
  components: { DropdownActionMenu,JobCardList },
  data() {
    const STATUS_MAPPING = {
      1: {
        name: "Pending",
      },
      2: {
        name: "Confirmed",
      },
      3: {
        name: "In progress",
      },
      4: {
        name: "Completed",
      },
      5: {
        name: "Cancelled",
      },
      6: {
        name: "Invalidated",
      },
    };
    const locationOptionProps = {
      value: "name",
      label: "name",
      children: "children",
    };
    const actionList = [
      { key: "view", label: "View", icon: "el-icon-view" },
      { key: "addRecipientEmail", label: "Add Recipient", icon: "el-icon-message" },
      { key: "shareEBDN", label: "Share EBDN", icon: "el-icon-message" },
      { key: "transferJob", label: "Transfer Job", icon: "el-icon-d-arrow-right" },
      { key: "sendEBDNToSage", label: "Push To Sage", icon: "el-icon-link" },
      {
        key: "viewBlockchainInfo",
        label: "Blockchain Info",
        icon: "el-icon-warning-outline",
      },
    ];
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      STATUS_MAPPING,
      cascaderKey: 0,
      locationOptionProps,
      actionList,
    };
  },
  computed: {
    ...mapState({
      currentCompany: "currentCompany",
      locationList: "locationList",
      isMobileScreen: "isMobileScreen",
      isVictory: "isVictory",
    }),
    paginationSetting(){
      return this.isMobileScreen?"total,prev, pager, next":"total, sizes, prev, pager, next, jumper"
    }
  },
  watch: {
    locationList() {
      this.cascaderKey++;
    },
  },
  methods: {
    formatUtcTimeString,
    getStatusTagStyle,
    disabledColumn(row) {
      return row;
    },
    getJobTableMenuList(data) {

      let menuList = this.actionList;
      if (
        [1, 4, 5, 6].includes(data.status) ||
        !data.bargeImo ||
        data.mitigatingCompanyOrgId !== this.currentCompany.id
      ) {
        menuList = menuList.filter((item) => item.key !== "transferJob");
      }

      // filter Loading Type
      if( data.type === 2 ) {
        menuList = menuList.filter((item) => item.key !== "addRecipientEmail" && item.key !== "shareEBDN");
      } else {
      // only Delivery & STS Type
        if( [1, 2, 3].includes(data.status) ) {
          menuList = menuList.filter((item) => item.key !== "shareEBDN");
        } else if ( [4,5,6].includes(data.status) ) {
          menuList = menuList.filter((item) => item.key !== "addRecipientEmail");
        }
      }

      if( [5,6].includes(data.status) ) {
        menuList = menuList.filter((item) => item.key !== "shareEBDN");
      }

      if( ! this.allowSendEBDNToSage(data) ) {
        menuList = menuList.filter((item) => item.key !== "sendEBDNToSage");
      }
      
      return menuList;
    },
    handleJobSelectionChange(val) {
      this.$emit("handleJobSelectionChange", val);
    },
    handleJobPageSizeChange(pageSize) {
      this.$emit("handleJobPageSizeChange", pageSize);
    },
    handleJobPageChange(pageNumber) {
      this.$emit("handleJobPageChange", pageNumber);
    },
    handleJobDropdownMenuClick(action, data) {
      this.$emit("handleJobDropdownMenuClick", action, data);
    },
    visibleChange(isVisible, row) {
      if (isVisible) {
        this.$emit("checkAvailableBargesOptions", true, row);
      }
    },
    handleChangeBarge(selectedBargeId, row) {
      if (selectedBargeId != null && selectedBargeId != undefined) {
        row.bargeShipId = selectedBargeId;
        const barges = this.availableBargesOptions
        const shipItem = barges
          ? this.availableBargesOptions.find((ship) => ship?.id === selectedBargeId)
          : {};
        row.bargeName = shipItem?.shipName;
        row.bargeImo = shipItem?.imo;
        if (row.type === 2) {
          this.editExwharfJob(row).then(() => {
            this.$emit("handleJobTableRefresh");
          });
        } else {
          this.editDeliveredJob(row).then(() => {
            this.$emit("handleJobTableRefresh");
          });
        }
      } else {
        this.$emit("handleJobTableRefresh");
      }
    },
    rowValueChange(row, type) {
      row = Object.assign({}, row, {
        locationLevel1: row.locationLevel[0],
        locationLevel2: row.locationLevel[1],
      });
      if (row.type === 2) {
        if (type === "datetime") {
          row.estimatedLoadingTime = row.datetime;
          row.loadingTime = row.datetime;
        }
        this.editExwharfJob(row).then(() => {
          this.$emit("handleJobTableRefresh");
        });
      } else {
        if (type === "datetime") {
          row.vesselEta = row.datetime;
        }
        this.editDeliveredJob(row).then(() => {
          this.$emit("handleJobTableRefresh");
        });
      }
    },
    editExwharfJob(data) {
      delete data.datetime;
      this.tableLoading = true;
      return this.$request
        .put({
          url: this.$apis.editExWharfJob,
          data,
        })
        .then((data) => {
          if (data?.code === 1000) {
            this.$message.success(data.message);
          } else {
            this.$message.error(data.message);
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    editDeliveredJob(data) {
      this.tableLoading = true;
      return this.$request
        .put({
          url: this.$apis.editDeliveredJob,
          data,
        })
        .then((data) => {
          if (data?.code === 1000) {
            this.$message.success(data.message);
          } else {
            this.$message.error(data.message);
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    isJobCreatorOrCustomerEditView(row) {
      // only  job mitigating Company is allowed edit
      return  this.currentCompany.id === row.mitigatingCompanyOrgId

         //( || this.currentCompany.id === row.buyerSysOrganizationId ||
        // this.currentCompany.id === row.sellerSysOrganizationId
      // );
    },

    showReissued( job ) {
      return [4, 6].includes(job.status) && job.originalJobId != null && job.originalJobId != ''
    },
    
    showReissuanceInProgress( job ) {
      return [3].includes(job.status) && job.originalJobId != null && job.originalJobId != ''
    },

    showDuplicated(job) {
      return [3,4].includes(job.status) && job.originalJobId != null && job.originalJobId != ''
    },

    showInvalidationInProgress(job) {
      return job.status == 4 && job.duplicateJobId != null && job.duplicateJobId != ''
    },

    allowSendEBDNToSage(job) {
      return job.status == 4 && this.isVictory
    },

    splitInvalidatedBdnNumber(invalidatedBdnNumber) {
      return invalidatedBdnNumber ? invalidatedBdnNumber.split(', ') : []
    },

  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-item:hover {
  background-color: #e7ebf0;
  color: #3f5f88;
}
.mpa-status-flag {
  // background-color: rgb(78, 229, 88) !important;

  width: 16px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 8;
  line-height: 16px;
  //   width: 0;
  // height: 0;
  // border-bottom: 5px solid white;
  // border-left: 15px solid transparent;
}

.duplicated { 
  width: 100%;
  padding:0px;
  padding-top: 5px;
  text-align: left;

  img {
    height: 23px;
  }
}

.invalidationInProgressButton {
  width: 100%;
  padding:0px;
  padding-top: 5px;
  text-align: left;

  img {
    height: 25px;
  }
}
</style>
